<template>

  
	<div class="map-div" id="map2">
		<!-- 지도가 들어가는 영역 -->
		
		<!-- // 지도가 들어가는 영역 -->
	</div>
</template>
<script>
  import EventBus from "@/utils/CommonEventBus";
  var nMap
  export default {
    name: 'KakaoMap2',
    props:['invitation','real'],
    components: {

    }
    , data() {
      return {
        map : null,
        marker: null
      }
    }

    , 
    mounted() {
      const context =this;
      
      EventBus.$on('changeMarketPosition',() => {
        context.changeMarkerPosition();
      });
      EventBus.$on('mapExtendsYn',(data) => {
        context.map.setDraggable(data);   
        context.map.setZoomable(data);   
      });
      EventBus.$on("searchAddress", (address) => {

        var geocoder = new window.kakao.maps.services.Geocoder();
        geocoder.addressSearch(
          address,  // 검색된 주소
          function (result, status) {
            // 5) 성공시 좌표 값을 가져온다.
            if (status === window.kakao.maps.services.Status.OK) {
              var currentPos = new window.kakao.maps.LatLng(
                result[0].y,
                result[0].x
              );
              context.map.panTo(currentPos);
              // 결과값으로 받은 위치를 마커로 표시합니다

              
              context.marker = new window.kakao.maps.Marker({
                position: currentPos
              })

              context.marker.setMap(context.map)
        
            }

                
            const param = {
                invitationAddress : address,
                invitationAddressX: result[0].x,
                invitationAddressY: result[0].y,
            }

            context.$emit('changeAddressData',param);
            
          }
        );


      });

      
        EventBus.$on("invitationInit", () => {
		      setTimeout(function() {

            if(window.kakao && window.kakao.maps){
              context.loadMap();
            }else{
              context.loadScript();
            }

          }, 300);

        });
        if(this.real){
          
		      setTimeout(function() {

            if(window.kakao && window.kakao.maps){
              context.loadMap();
            }else{
              context.loadScript();
            }

          }, 300);
        }
        
      
    },
    beforeDestroy() {
    },
    methods: {
      loadScript(){
        const script = document.createElement('script');
        script.src= "//dapi.kakao.com/v2/maps/sdk.js?appkey=a489a3f33e03e97a14fd62d585935b58&autoload=false&libraries=services";
        script.onload = () => window.kakao.maps.load(this.loadMap);

        document.head.appendChild(script);
      },
      loadMap(){
        const container = document.getElementById('map2');

        let options = null;
        
        if(this.invitation.invitationAddressX!==null && this.invitation.invitationAddressX!==undefined && this.invitation.invitationAddressX!=='')
          options = {
            center: new window.kakao.maps.LatLng(this.invitation.invitationAddressY,this.invitation.invitationAddressX),
            level: 3
          };
        else
          options = {
            center: new window.kakao.maps.LatLng(33.450701,126.570667),
            level: 3
          };
          

        this.map = new window.kakao.maps.Map(container, options);

        this.loadMarker()
        this.loadExtends()
      },
      loadMarker(){
        let markerPostion = null;
        if(this.invitation.invitationAddressX!==null && this.invitation.invitationAddressX!==undefined  && this.invitation.invitationAddressX!=='')
          markerPostion = new window.kakao.maps.LatLng(this.invitation.invitationAddressY,this.invitation.invitationAddressX)
        else
          markerPostion = new window.kakao.maps.LatLng(33.450701,126.570667)

        this.marker = new window.kakao.maps.Marker({
          position: markerPostion
        })

        this.marker.setMap(this.map)
      },
      loadExtends(){
        this.map.setDraggable(this.invitation.invitationAddressExtendsYn !== 'Y');   
        this.map.setZoomable(this.invitation.invitationAddressExtendsYn !== 'Y');   
      },
      changeMarkerPosition(){
        if(this.map!==null){
          var markerPosition = this.marker.getPosition(); 
          this.map.relayout();
          this.map.setCenter(markerPosition);
        }
      }

    }
  }
</script>
<style scoped>

</style>