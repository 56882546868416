<template>	
	<div :class="['last-img',scrollMotion]" v-if="invitation.invitationEndYn === 'Y'">

    
		<div class="img-div">
			<p class="bg">
        <img v-if="isEmpty(invitation.invitationEndImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
        <img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationEndImageFile)" alt="">

      </p>
			<p class="txt" v-html="getLineReplace(invitation.invitationEndText)">
				
			</p>
		</div>
	</div>
</template>


<script>
export default {
  name: 'WritePreviewEnd',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

  },

}
</script>
