<template>	
	
	<div v-if="invitation.invitationYoutubeYn==='Y' " class="video">
		<div :class="['tit-wrap',scrollMotion]">
			<p class="sub-tit">VIDEO</p>
			<p class="tit">{{invitation.invitationYoutubeTitle}}</p>
		</div>
		<!-- 비디오 영역 -->
		<div :class="['video-div',scrollMotion]"  >
			<!-- iframe width와 height는 100%로 부탁드립니다. -->
			<iframe width="100%" height="100%" :src="getEmbededUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
		</div>
		<!-- // 비디오 영역 -->
	</div>
</template>


<script>
export default {
  name: 'WritePreviewYoutube',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
    getEmbededUrl(){
      var url  = ''
      if(this.invitation.invitationYoutube!==null && this.invitation.invitationYoutube!==undefined){

        const videoId = this.getYoutubeId(this.invitation.invitationYoutube);
        url = '//www.youtube.com/embed/' + videoId
      }

      
      return url;
    },
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){

     this.$nextTick(function () {
      // Code that will run only after the
      // entire view has been rendered
    })
    
  },
  
  methods: {
      
    getYoutubeId(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
      ? match[2]
      : null;
    },

  },

}
</script>
