<template>	
	<div class="attendance" v-if="invitation.invitationAttendYn=== 'Y'">
		<div :class="['tit-wrap',scrollMotion]">
			<p class="sub-tit">RSVP</p>
			<p class="tit">{{invitation.invitationAttendTitle}}</p>
		</div>
		<p :class="['txt',scrollMotion]" v-html="getLineReplace(invitation.invitationAttendContent)" >
		</p>
		<div :class="['attend-deliver',scrollMotion]">
			<a href="javascript:" @click="clickAttendPopup" class="deliver-btn"><span>참석 의사 전달하기</span></a>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WritePreviewAttend',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {
    clickAttendPopup(){
      
      const param = {
        flag: 'attendPop1',
        invitation: this.invitation
      }
      EventBus.$emit('layerPopup',param)
    }
  },

}
</script>
