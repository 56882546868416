<template>	
	<p :class="['link-copy']" v-if="invitation.invitationUrlShareThumbnailYn === 'Y'">
    <a href="javascript:" @click="clickCopyLink"><span>링크 복사하기</span></a>
  </p>
</template>


<script>
export default {
  name: 'WritePreviewUrlShareThumbnail',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {
    clickCopyLink(){
      
    	this.clickCopyTxt(this.invitation.invitationAesPath);
    }

  },

}
</script>
