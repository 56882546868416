<template>	
	
  <div class="gallery" v-if="invitation.invitationGalleryYn === 'Y'">
		<div :class="['tit-wrap',scrollMotion]">
			<p class="sub-tit">GALLERY</p>
			<p class="tit">{{invitation.invitationGalleryTitle}}</p>
		</div>
		<div :class="['gallery-slide-w',scrollMotion]">
			<!--
				** 선택한 슬라이드 형태에 따라 활성화 부탁드립니다.
			//  -->
			<!-- 기본 슬라이드 -->
			<WritePreviewGalleryBasic  v-if="invitation.invitationGalleryType === 'basic'" :invitation="invitation"/>
			<!-- // 기본 슬라이드 -->
			<!-- 썸네일 슬라이드 -->
			<WritePreviewGalleryThubnail  v-if="invitation.invitationGalleryType === 'thumbnail'" :invitation="invitation"/>
			<!-- // 썸네일 슬라이드 -->
			<!-- 그리드 슬라이드 -->
			<WritePreviewGalleryGride  v-if="invitation.invitationGalleryType === 'grid'" :invitation="invitation"/>
			<!-- //  그리드 슬라이드 -->
		</div>
	</div>
</template>


<script>
import WritePreviewGalleryBasic from "@/components/write/preview/components/WritePreviewGalleryBasic.vue";
import WritePreviewGalleryThubnail from "@/components/write/preview/components/WritePreviewGalleryThubnail.vue";
import WritePreviewGalleryGride from "@/components/write/preview/components/WritePreviewGalleryGride.vue";
export default {
  name: 'WritePreviewGallery',
  props:['invitation','real','scrollMotion'],
  components: {WritePreviewGalleryBasic,WritePreviewGalleryThubnail,WritePreviewGalleryGride},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

  },

}
</script>
