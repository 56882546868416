<template>	
	<p :class="['kakao-share']" v-if="invitation.invitationKakaoShareThumbnailYn === 'Y'">
    <a href="javascript:" @click="clickCopyKakaoLink()"><span>카카오톡으로 공유</span></a>
  </p>
</template>


<script>
export default {
  name: 'WritePreviewKakaoShareThumbnail',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

    clickCopyKakaoLink(){
      
      const parameter = {
        invitation:this.invitation
      }
      const context = this
      this.$store.dispatch('REQUEST_SELECT_INVITATIONVIEW_KAKAO',parameter).then((data) => {
          debugger
          
          context.$store.commit('START_LOADING');
          var content = {}
          content.title= data.data.invitationKakaoShareThumbnailTitle
          content.description= data.data.invitationKakaoShareThumbnailContent
          if(data.data.thubnailUrl)
            content.imageUrl=data.data.thubnailUrl
          else
            content.imageUrl= process.env.VUE_APP_BASE_FRONT + '/pub/images/main-banner.png'
          content.link= {
                // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
                mobileWebUrl: data.data.redirectUrl,
                webUrl: data.data.redirectUrl,
              }
          Kakao.Share.sendDefault({
            objectType: 'feed',
            content: content,
            buttons: [
              {
                title: '청첩장보기',
                link: {
                  mobileWebUrl: data.data.redirectUrl,
                  webUrl: data.data.redirectUrl,
                },
              },
            ],
          });
          context.$store.commit('END_LOADING');

        
            
      })
    }
  },

}
</script>
