<template >	
	<div>
		<div class="calendar-area" v-if="isEmpty(invitation.invitationTimeDatetime)">
			<div :class="['tit-wrap',scrollMotion]">
				<p class="sub-tit">WEDDING DAY</p>
			</div>
			<p :class="['date-tit',scrollMotion]">2024.2.28.</p>
			<p :class="['time',scrollMotion]">일요일 오후 2시 30분</p>
			<div :class="['calendar',scrollMotion]" v-if="invitation.invitationTimeCalendarYn === 'Y'">
				<div class="day-div">
					<p class="day">일</p>
					<p class="day">월</p>
					<p class="day">화</p>
					<p class="day">수</p>
					<p class="day">목</p>
					<p class="day">금</p>
					<p class="day">토</p>
				</div>
				<div class="date-div">
					<p class="date"><span></span></p>
					<p class="date"><span>1</span></p>
					<p class="date"><span>2</span></p>
					<p class="date"><span>3</span></p>
					<p class="date"><span>4</span></p>
					<p class="date"><span>5</span></p>
					<p class="date"><span>6</span></p>
					<p class="date"><span>7</span></p>
					<p class="date"><span>8</span></p>
					<p class="date"><span>9</span></p>
					<p class="date"><span>10</span></p>
					<p class="date"><span>11</span></p>
					<p class="date"><span>12</span></p>
					<p class="date"><span>13</span></p>
					<p class="date"><span>14</span></p>
					<p class="date"><span>15</span></p>
					<p class="date"><span>16</span></p>
					<p class="date"><span>17</span></p>
					<p class="date"><span>18</span></p>
					<p class="date"><span>19</span></p>
					<p class="date"><span>20</span></p>
					<p class="date"><span>21</span></p>
					<p class="date"><span>22</span></p>
					<p class="date"><span>23</span></p>
					<p class="date"><span>24</span></p>
					<p class="date"><span>25</span></p>
					<p class="date"><span>26</span></p>
					<p class="date"><span>27</span></p>
					<p class="date on"><span>28</span></p>
					<p class="date"><span>29</span></p>
					<p class="date"><span>30</span></p>
					<p class="date"><span></span></p>
					<p class="date"><span></span></p>
					<p class="date"><span></span></p>
					<p class="date"><span></span></p>
				</div>
			</div>
			<div :class="['d-day',scrollMotion]" v-if="invitation.invitationTimeDdayYn === 'Y'">
				<div class="txt-div">
					<p class="num">157</p>
					<p class="txt">days</p>
				</div>
				<div class="txt-div">
					<p class="num">22</p>
					<p class="txt">hours</p>
				</div>
				<div class="txt-div">
					<p class="num">08</p>
					<p class="txt">minuites</p>
				</div>
				<div class="txt-div">
					<p class="num">59</p>
					<p class="txt">seconds</p>
				</div>
			</div>
			<p :class="['comment',scrollMotion]" v-if="invitation.invitationTimeDdayYn === 'Y'">신랑&amp;신부의 결혼식이 <span>157일</span> 남았습니다.</p>
		</div>
		<div class="calendar-area" v-else>
			<div :class="['tit-wrap',scrollMotion]">
				<p class="sub-tit">WEDDING DAY</p>
			</div>
			<p :class="['date-tit',scrollMotion]">{{getYYYYCommaMMCommaDDCommaTime(invitation.invitationTimeDatetime)}}</p>
			<p :class="['time',scrollMotion]">{{getKorTime(invitation.invitationTimeDatetime)}}</p>
			<div :class="['calendar',scrollMotion]"  v-if="invitation.invitationTimeCalendarYn === 'Y'">
				<div class="day-div">
					<p class="day">일</p>
					<p class="day">월</p>
					<p class="day">화</p>
					<p class="day">수</p>
					<p class="day">목</p>
					<p class="day">금</p>
					<p class="day">토</p>
				</div>
				<div class="date-div">
					<p class="date"  v-for="empty in startDay" :key="empty +'A'+ i"> <span></span></p>

					<p v-for="date in lastDate" :key="date"  :class="['date',selectedDate === date?'on':'']"><span>{{date}}</span></p>
					
					<p class="date"  v-for="empty in lastDay" :key="empty +'B'+ i"> <span></span></p>
					
				</div>
			</div>
			<div :class="['d-day',scrollMotion]"  v-if="invitation.invitationTimeDdayYn === 'Y'">
				<div class="txt-div">
					<p class="num">{{diffTime.day}}</p>
					<p class="txt">days</p>
				</div>
				<div class="txt-div">
					<p class="num">{{diffTime.hour}}</p>
					<p class="txt">hours</p>
				</div>
				<div class="txt-div">
					<p class="num">{{diffTime.minute}}</p>
					<p class="txt">minuites</p>
				</div>
				<div class="txt-div">
					<p class="num">{{diffTime.second}}</p>
					<p class="txt">seconds</p>
				</div>
			</div>
			<p :class="['comment',scrollMotion]" v-if="invitation.invitationTimeDdayYn === 'Y'">신랑&amp;신부의 결혼식이 <span>{{diffTime.day}}일</span> 남았습니다.</p>
		</div>
	</div>
</template>


<script>
export default {
  name: 'WritePreviewTime',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
	startDay(){
		return Number(this.getStartDay(this.invitation.invitationTimeDatetime))
	},
	lastDate(){
		return Number(this.getLastDate(this.invitation.invitationTimeDatetime))
	},
	lastDay(){
		return 6-Number(this.getLastDay(this.invitation.invitationTimeDatetime))
	},
	selectedDate(){
		return Number(this.getDay(this.invitation.invitationTimeDatetime))
	},
  },
  data(){
    return {
		diffTime:{
			
			day: '0',
			hour: '0',
			minute: '0',
			second: '0',
		},
		i:0,
		intervalTimeInterval:null
    }
  },
  
  created(){
    
  },
  mounted(){
	this.diffTime = this.getDday(this.invitation.invitationTimeDatetime)
	this.intervalTimeInterval = setInterval(() => {
		this.i ++;
		if(!this.isEmpty(this.invitation.invitationTimeDatetime)){
			this.diffTime = this.getDday(this.invitation.invitationTimeDatetime)
		}
	},1000);
  },
  methods: {

  },
  beforeDestroy(){
	clearInterval(this.intervalTimeInterval)
  }

}
</script>
