<template>	
	<!-- 기본 슬라이드 -->
	<div class="slide-wrap" :key="changeKey">
		<div class="swiper basic-slide">
			<div class="swiper-wrapper">
				<template v-if="invitation.invitationGalleryImagesFile!==undefined && invitation.invitationGalleryImagesFile!==null && invitation.invitationGalleryImagesFile.length>0">
					<div class="swiper-slide" v-for="(invitationGalleryImageFile,index) in invitation.invitationGalleryImagesFile" :key="index">
						<img :src="eixstIdMakeDisplayUrl(invitationGalleryImageFile)" alt="">
					</div>
				</template>
				<template v-else>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
					<div class="swiper-slide"><img :src="`${publishpath}pub/images/img-main-visual.jpg`" alt=""></div>
				</template>
			</div>
			<div class="swiper-button-next"></div>
			<div class="swiper-button-prev"></div>
			<div class="swiper-pagination"></div>
		</div>
	</div>
	<!-- // 기본 슬라이드 -->
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WritePreviewGalleryBasic',
  props:['invitation'],
  components: {},
  computed:{
  },
  data(){
    return {
		changeKey:0
    }
  },
  
  created(){
    
  },
  mounted(){
	const context = this
    EventBus.$on("invitationInit", (invitationInit) => {
		context.changeKey += 1;
		setTimeout(function() { context.subScript.swiperFn(); }, 100);
	});
  },
  beforeDestroy(){
    //EventBus.$off('invitationInit')
  },
  methods: {

  },

}
</script>
