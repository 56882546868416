<template>
	<div class="invitation">
		<div :class="['tit-wrap',scrollMotion] ">
			<p class="sub-tit">INVITATION</p>
			<p class="tit">{{invitation.invitationGreetingWordTitle}}</p>
		</div>
		<p :class="['txt',scrollMotion]" v-html="getLineReplace(invitation.invitationGreetingWordContent)">
		</p>
		<div :class="['family-div', getParentsIsEmpty(invitation)?'type2':'type1', scrollMotion] " v-if="invitation.invitationGreetingNameVisibleYn==='Y'">
			<!--
				type1 : 부모님 기입 했을 경우
				type2 : 부모님 기입 안했을 경우
			// -->
			<template v-if="invitation.invitationInfoBrideFirstYn === 'N'">
				<div class="family-wrap">
					<div class="family">
						<div class="parent">
							<p :class="getDieClass(invitation,invitation.invitationInfoGroomFatherDieYn)" v-if="!isEmpty(invitation.invitationInfoGroomFatherName)">{{invitation.invitationInfoGroomFatherName}}</p>
							<p :class="getDieClass(invitation,invitation.invitationInfoGroomMotherDieYn)" v-if="!isEmpty(invitation.invitationInfoGroomMotherName)">{{invitation.invitationInfoGroomMotherName}}</p>
						</div>
						<p class="children">의 {{invitation.invitationInfoGroomFamilyLevel}}</p>
						<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
					</div>
					<div class="family">
						<div class="parent">
							<p :class="getDieClass(invitation,invitation.invitationInfoBrideFatherDieYn)" v-if="!isEmpty(invitation.invitationInfoBrideFatherName)">{{invitation.invitationInfoBrideFatherName}}</p>
							<p :class="getDieClass(invitation,invitation.invitationInfoBrideMotherDieYn)" v-if="!isEmpty(invitation.invitationInfoBrideMotherName)">{{invitation.invitationInfoBrideMotherName}}</p>
						</div>
						<p class="children">의 {{invitation.invitationInfoBrideFamilyLevel}}</p>
						<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
					</div>
				</div>
				<div class="family-wrap">
					<div class="family">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" class="only-name">
							<div class="name-div">
								<span>신랑</span>
								김신랑
							</div>
							<div class="name-div">
								<span>신부</span>
								최신부
							</div>
						</div>
						<div v-else class="only-name">
							<div class="name-div">
								<span>신랑</span>
								{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}
							</div>
							<div class="name-div">
								<span>신부</span>
								{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}
							</div>
						</div>
					</div>
				</div>

			</template>
			<template v-else>
				<div class="family-wrap">
					<div class="family">
						<div class="parent">
							<p :class="getDieClass(invitation,invitation.invitationInfoBrideFatherDieYn)" v-if="!isEmpty(invitation.invitationInfoBrideFatherName)">{{invitation.invitationInfoBrideFatherName}}</p>
							<p :class="getDieClass(invitation,invitation.invitationInfoBrideMotherDieYn)" v-if="!isEmpty(invitation.invitationInfoBrideMotherName)">{{invitation.invitationInfoBrideMotherName}}</p>
						</div>
						<p class="children">의 {{invitation.invitationInfoBrideFamilyLevel}}</p>
						<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
					</div>
					<div class="family">
						<div class="parent">
							<p :class="getDieClass(invitation,invitation.invitationInfoGroomFatherDieYn)" v-if="!isEmpty(invitation.invitationInfoGroomFatherName)">{{invitation.invitationInfoGroomFatherName}}</p>
							<p :class="getDieClass(invitation,invitation.invitationInfoGroomMotherDieYn)" v-if="!isEmpty(invitation.invitationInfoGroomMotherName)">{{invitation.invitationInfoGroomMotherName}}</p>
						</div>
						<p class="children">의 {{invitation.invitationInfoGroomFamilyLevel}}</p>
						<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
					</div>
				</div>
				<div class="family-wrap">
					<div class="family">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" class="only-name">
							<div class="name-div">
								<span>신부</span>
								최신부
							</div>
							<div class="name-div">
								<span>신랑</span>
								김신랑
							</div>
						</div>
						<div v-else class="only-name">
							<div class="name-div">
								<span>신부</span>
								{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}
							</div>
							<div class="name-div">
								<span>신랑</span>
								{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}
							</div>
						</div>
					</div>
				</div>

			</template>
		</div>
		<div :class="['call-btn',scrollMotion]" v-if="invitation.invitationContactYn === 'Y'">
			<a @click="clickContachPopup"><span>연락하기</span></a>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
export default {
  name: 'WritePreviewGreetingWord',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {
		clickContachPopup(){
			
			const param = {
				flag: 'contactPop',
				data: this.invitation
			}
			EventBus.$emit('layerPopup',param)
		}
	

  },

}
</script>
