<template>
	<div :class="['main-visual',invitation.invitationMainType,invitation.invitationMainSubType]" >
		<!--
			** 메인 화면 basic, arch, fill 3가지 타입 **
			** type1, 2, 3, 4, 5 존재 (arch는 4가 없음) **
		// -->
		
		<template v-if="invitation.invitationInfoBrideFirstYn === 'N'">
			
			<!--type1-->
			<div>
				<div :class="['top-area',scrollMotion] ">
					<p class="img">
						<img :src="`${publishpath}pub/images/save-the-date-txt.svg`" alt="">
					</p>
					<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">
						<span>2024</span>
						<span>01</span>
						<span>31</span>
					</p>
					<p class="date" v-else>
						<span>{{getYear(invitation.invitationTimeDatetime)}}</span>
						<span>{{getMonth(invitation.invitationTimeDatetime)}}</span>
						<span>{{getDay(invitation.invitationTimeDatetime)}}</span>
					</p>
				</div>
				<div :class="['img-area',scrollMotion] ">
						<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
						<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<div :class="['bot-area',scrollMotion] ">
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<p v-if="getGroomBrideNameIsEmpty(invitation)" class="name">
							<span>김신랑</span>
							<span>최신부</span>
						</p>
						<p v-else class="name">
							<span>{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</span>
							<span>{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</span>
						</p>
					</template>
					<template v-else>
						<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
							<p v-if="getGroomBrideNameIsEmpty(invitation)" class="name" :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<span>김신랑</span>
								<span>최신부</span>
							</p>
							<p v-else class="name" :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<span>{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</span>
								<span>{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</span>
							</p>
						</template>

						<p v-else class="name" :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<span>{{invitationMainTextAndColorJson.groomName}}</span>
							<span>{{invitationMainTextAndColorJson.brideName}}</span>
						</p>
					</template>
					
					<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
						<p class="place" v-if="getTimeAndAddressIsEmpty(invitation)">
							2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀
						</p>
						<p class="place" v-else>
							{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}
						</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor"  v-if="getTimeAndAddressIsEmpty(invitation)">
								2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀
							</p>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor"  v-else>
								{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}
							</p>
						</template>
						<p v-else class="place"  :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)">
						</p>
					</template>
				</div>
			</div>


			<!--type2-->

			<div>
				
				<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
					<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
						<p class="name">김신랑</p>
						<p class="and">그리고</p>
						<p class="name">최신부</p>
					</div>
					<div v-else :class="['top-area',scrollMotion] ">
						<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						<p class="and">그리고</p>
						<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
					</div>
				</template>
				<template v-else>

					<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] " :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">김신랑</p>
							<p class="and">그리고</p>
							<p class="name">최신부</p>
						</div>
						<div v-else :class="['top-area',scrollMotion] " :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
							<p class="and">그리고</p>
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
						</div>
					</template>
					<div v-else :class="['top-area',scrollMotion] " :style="'color:'+invitationMainTextAndColorJson.nameColor">
						<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
						<p class="and">{{isEmpty(invitationMainTextAndColorJson.and)?'그리고':invitationMainTextAndColorJson.and}}</p>
						<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
					</div>
				</template>
				
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="txt">두 사람, 결혼합니다.</p>
						</template>
						<template v-else>
							<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
						</template>
						<p class="place">2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="txt">두 사람, 결혼합니다.</p>
						</template>
						<template v-else>
							<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
						</template>
						<p class="place">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
						<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>
							<p class="place"  :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
						</div>
						<div :class="['bot-area',scrollMotion] " v-else>
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>							
							<p class="place"  :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</div>
					</template>
					<div v-else :class="['bot-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>

			
			<!--type3-->
			<div>
				
				<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
					<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="sub-tit">The marriage of</p>
						</template>
						<template v-else>
							<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
							<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
						</template>
						<p class="name">김신랑 <span>&amp;</span> 최신부</p>
					</div>
					<div v-else :class="['top-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="sub-tit">The marriage of</p>
						</template>
						<template v-else>
							<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
							<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
						</template>
						<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}} <span>&amp;</span> {{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="sub-tit">The marriage of</p>
							</template>
							<template v-else>
								<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
								<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
							<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">김신랑 <span>&amp;</span> 최신부</p>
						</div>
						<div v-else :class="['top-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="sub-tit">The marriage of</p>
							</template>
							<template v-else>
								<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
								<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>							<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}} <span>&amp;</span> {{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
						</div>
					</template>
					<div v-else :class="['top-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="sub-tit">The marriage of</p>
							</template>
							<template v-else>
								<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
								<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
							<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">{{invitationMainTextAndColorJson.groomName}} <span>{{isEmpty(invitationMainTextAndColorJson.and)?'&amp;':invitationMainTextAndColorJson.and}}</span> {{invitationMainTextAndColorJson.brideName}}</p>
					</div>
				</template>
									
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
						<p class="sub-tit" :style="invitationMainTextAndColorJson.dateCheckYn !== 'Y' || isEmpty(invitationMainTextAndColorJson.date)?'':'color:'+invitationMainTextAndColorJson.dateColor">{{invitationMainTextAndColorJson.dateCheckYn !== 'Y' || isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
						<p class="place">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
						<p class="sub-tit" :style="invitationMainTextAndColorJson.dateCheckYn !== 'Y' || isEmpty(invitationMainTextAndColorJson.date)?'':'color:'+invitationMainTextAndColorJson.dateColor">{{invitationMainTextAndColorJson.dateCheckYn !== 'Y' || isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
						<p class="place">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
						<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
						</div>
						<div :class="['bot-area',scrollMotion] " v-else>
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</div>
					</template>
					<div v-else :class="['bot-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
							<p class="sub-tit" >Save the Date</p>
						</template>
						<template v-else>
							<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
						</template>
						<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor"  v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>


			<!--type4-->
			<div>
				<div :class="['top-area',scrollMotion] ">
					
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)">
							<p class="name">김신랑</p>
							<p class="name">최신부</p>
						</div>
						<div class="name-div" v-else>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
						</div>
					</template>
					<template v-else>
						<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
							<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">김신랑</p>
								<p class="name">최신부</p>
							</div>
							<div class="name-div" v-else  :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
								<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							</div>
						</template>

						<div v-else class="name-div"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
							<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
						</div>
					</template>
					<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
						<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">1월 18일, 저희 결혼합니다.</p>
						<p class="date" v-else>{{getMonth(invitation.invitationTimeDatetime)}}월{{getDay(invitation.invitationTimeDatetime)}}일, 저희 결혼합니다.</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
							<p class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor" v-if="isEmpty(invitation.invitationTimeDatetime)">1월 18일, 저희 결혼합니다.</p>
							<p class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor" v-else>{{getMonth(invitation.invitationTimeDatetime)}}월{{getDay(invitation.invitationTimeDatetime)}}일, 저희 결혼합니다.</p>
						</template>
						<p v-else class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
					</template>
				</div>
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				
				<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
						<p class="place">2025년 1월 8일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
						<p class="place">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
						
					<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
						<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025년 1월 8일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
						</div>
						<div :class="['bot-area',scrollMotion] " v-else>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</div>
					</template>
					<div v-else :class="['bot-area',scrollMotion] ">
						<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>


			<!-- type5 -->
			<div>
				<div :class="['top-area',scrollMotion] ">
					
					<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
						<p class="sub-tit">OUR WEDDING DAY</p>
					</template>
					<template v-else>
						<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{isEmpty(invitationMainTextAndColorJson.welcome)?'OUR WEDDING DAY':invitationMainTextAndColorJson.welcome}}</p>
					</template>
					<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">
						<span>2024</span>
						<span>01</span>
						<span>31</span>
					</p>
					<p class="date" v-else>
						<span>{{getYear(invitation.invitationTimeDatetime)}}</span>
						<span>{{getMonth(invitation.invitationTimeDatetime)}}</span>
						<span>{{getDay(invitation.invitationTimeDatetime)}}</span>
					</p>
				</div>
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<div :class="['bot-area',scrollMotion] ">
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)">
							<p class="name">김신랑</p>
							<p class="name">최신부</p>
						</div>
						<div class="name-div" v-else>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
						</div>
					</template>
					<template v-else>
						<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
							<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">김신랑</p>
								<p class="name">최신부</p>
							</div>
							<div class="name-div" v-else  :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
								<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							</div>
						</template>

						<div v-else class="name-div"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
							<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
						</div>
					</template>
					<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
						<p class="place" v-if="getTimeAndAddressIsEmpty(invitation)">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
						<p class="place">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-if="getTimeAndAddressIsEmpty(invitation)">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</template>
						<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</template>
				</div>
			</div>
		</template>
		<template v-else>
			<!--type1-->
			<div>
				<div :class="['top-area',scrollMotion] "> 
					<p class="img"><img :src="`${publishpath}pub/images/save-the-date-txt.svg`" alt=""></p>
					<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">
						<span>2024</span>
						<span>01</span>
						<span>31</span>
					</p>
					<p class="date" v-else>
						<span>{{getYear(invitation.invitationTimeDatetime)}}</span>
						<span>{{getMonth(invitation.invitationTimeDatetime)}}</span>
						<span>{{getDay(invitation.invitationTimeDatetime)}}</span>
					</p>
				</div>
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<div :class="['bot-area',scrollMotion] ">
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<p v-if="getGroomBrideNameIsEmpty(invitation)" class="name">
							<span>최신부</span>
							<span>김신랑</span>
						</p>
						<p v-else class="name">
							<span>{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</span>
							<span>{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</span>
						</p>
					</template>
					<template v-else>
						<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
							<p v-if="getGroomBrideNameIsEmpty(invitation)" class="name" :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<span>최신부</span>
								<span>김신랑</span>
							</p>
							<p v-else class="name" :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<span>{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</span>
								<span>{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</span>
							</p>
						</template>
						<p v-else class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<span>{{invitationMainTextAndColorJson.brideName}}</span>
							<span>{{invitationMainTextAndColorJson.groomName}}</span>
						</p>
					</template>
					
					<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
						<p class="place" v-if="getTimeAndAddressIsEmpty(invitation)">
							2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀
						</p>
						<p class="place" v-else>
							{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}
						</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
							<p class="place" v-if="getTimeAndAddressIsEmpty(invitation)"  :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">
								2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀
							</p>
							<p class="place" v-else  :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">
								{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}
							</p>
						</template>
						
						<p v-else class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)">
						</p>
					</template>
				</div>
			</div>


			<!--type2-->

			<div>
				
				<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
					<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
						<p class="name">최신부</p>
						<p class="and">그리고</p>
						<p class="name">김신랑</p>
					</div>
					<div v-else :class="['top-area',scrollMotion] ">
						<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
						<p class="and">그리고</p>
						<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] "  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">최신부</p>
							<p class="and">그리고</p>
							<p class="name">김신랑</p>
						</div>
						<div v-else :class="['top-area',scrollMotion] "  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							<p class="and">그리고</p>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						</div>
					</template>
					<div v-else :class="['top-area',scrollMotion] "  :style="'color:'+invitationMainTextAndColorJson.nameColor">
						<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
						<p class="and">{{isEmpty(invitationMainTextAndColorJson.and)?'그리고':invitationMainTextAndColorJson.and}}</p>
						<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
					</div>
				</template>
				
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="txt">두 사람, 결혼합니다.</p>
						</template>
						<template v-else>
							<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
						</template>
						<p class="place">2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="txt">두 사람, 결혼합니다.</p>
						</template>
						<template v-else>
							<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
						</template>
						<p class="place">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
						<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025년 4월 28일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
						</div>
						<div :class="['bot-area',scrollMotion] " v-else>
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</div>
					</template>
					<div v-else :class="['bot-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="txt">두 사람, 결혼합니다.</p>
							</template>
							<template v-else>
								<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">두 사람, 결혼합니다.</p>
								</template>
								<template v-else>
									<p class="txt" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
								</template>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>

			
			<!--type3-->
			<div>
				
				<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
					<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="sub-tit">The marriage of</p>
						</template>
						<template v-else>
							<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
							<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
						</template>
						<p class="name">최신부 <span>&amp;</span> 김신랑</p>
					</div>
					<div v-else :class="['top-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="sub-tit">The marriage of</p>
						</template>
						<template v-else>
							<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
							<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
						</template>
						<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}} <span>&amp;</span> {{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
					</div>
				</template>
				<template v-else>

						
					<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
						<div v-if="getGroomBrideNameIsEmpty(invitation)" :class="['top-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="sub-tit">The marriage of</p>
							</template>
							<template v-else>
								<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
								<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
							<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">최신부 <span>&amp;</span> 김신랑</p>
						</div>
						<div v-else :class="['top-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
								<p class="sub-tit">The marriage of</p>
							</template>
							<template v-else>
								<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
								<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
							</template>
							<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}} <span>&amp;</span> {{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						</div>
					</template>
					<div v-else :class="['top-area',scrollMotion] ">
						<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
							<p class="sub-tit">The marriage of</p>
						</template>
						<template v-else>
							<p v-if="isEmpty(invitationMainTextAndColorJson.welcome)" class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">The marriage of</p>
							<p v-else class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
						</template>
						<p class="name"  :style="'color:'+invitationMainTextAndColorJson.nameColor">{{invitationMainTextAndColorJson.brideName}} <span>{{isEmpty(invitationMainTextAndColorJson.and)?'&amp;':invitationMainTextAndColorJson.and}}</span> {{invitationMainTextAndColorJson.groomName}}</p>
					</div>
				</template>
									
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<template v-if=" invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
				<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
					<div :class="['bot-area',scrollMotion] ">
							<template v-if="invitationMainTextAndColorJson.dateCheckYn !== 'Y'">
								<p class="sub-tit" >Save the Date</p>
							</template>
							<template v-else>
								<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.dateColor">{{isEmpty(invitationMainTextAndColorJson.date)?'Save the Date':invitationMainTextAndColorJson.date}}</p>
							</template>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor"  v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>


			<!--type4-->
			<div>
				<div :class="['top-area',scrollMotion] ">
					
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)">
							<p class="name">최신부</p>
							<p class="name">김신랑</p>
						</div>
						<div class="name-div" v-else>
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						</div>
					</template>
					<template v-else>
							
						<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName))">
							<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)"   :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">최신부</p>
								<p class="name">김신랑</p>
							</div>
							<div class="name-div" v-else   :style="'color:'+invitationMainTextAndColorJson.nameColor">
								<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
								<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
							</div>
						</template>
						
						<div v-else class="name-div"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
							<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
						</div>
					</template>
					<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
						<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">1월 18일, 저희 결혼합니다.</p>
						<p class="date" v-else>{{getMonth(invitation.invitationTimeDatetime)}}월{{getDay(invitation.invitationTimeDatetime)}}일, 저희 결혼합니다.</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.welcome)">
							<p class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor" v-if="isEmpty(invitation.invitationTimeDatetime)">1월 18일, 저희 결혼합니다.</p>
							<p class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor" v-else>{{getMonth(invitation.invitationTimeDatetime)}}월{{getDay(invitation.invitationTimeDatetime)}}일, 저희 결혼합니다.</p>
						</template>
						<p v-else class="date" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{invitationMainTextAndColorJson.welcome}}</p>
					</template>
				</div>
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				
				<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
					<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
						<p class="place">2025년 1월 8일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
					</div>
					<div :class="['bot-area',scrollMotion] " v-else>
						<p class="place">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</div>
				</template>
				<template v-else>
					<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
						<div :class="['bot-area',scrollMotion] " v-if="getTimeAndAddressIsEmpty(invitation)">
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">2025년 1월 8일 토요일 오후 1시 30분<br />비비드마레호텔 5층 가든하우스홀</p>
						</div>
						<div :class="['bot-area',scrollMotion] " v-else>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKorDatetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</div>
					</template>
					<div :class="['bot-area',scrollMotion] ">
						<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</div>

				</template>
			</div>


			<!-- type5 -->
			<div>
				<div :class="['top-area',scrollMotion] ">
					
					<template v-if="invitationMainTextAndColorJson.welcomeCheckYn !== 'Y'">
						<p class="sub-tit">OUR WEDDING DAY</p>
					</template>
					<template v-else>
						<p class="sub-tit" :style="'color:'+invitationMainTextAndColorJson.welcomeColor">{{isEmpty(invitationMainTextAndColorJson.welcome)?'OUR WEDDING DAY':invitationMainTextAndColorJson.welcome}}</p>
					</template>
					<p class="date" v-if="isEmpty(invitation.invitationTimeDatetime)">
						<span>2024</span>
						<span>01</span>
						<span>31</span>
					</p>
					<p class="date" v-else>
						<span>{{getYear(invitation.invitationTimeDatetime)}}</span>
						<span>{{getMonth(invitation.invitationTimeDatetime)}}</span>
						<span>{{getDay(invitation.invitationTimeDatetime)}}</span>
					</p>
				</div>
				<div :class="['img-area',scrollMotion] ">
					<img v-if="isEmpty(invitation.invitationMainImageFile)" :src="`${publishpath}pub/images/img-main-visual.jpg`" alt="">
					<img v-else :src="eixstIdMakeDisplayUrl(invitation.invitationMainImageFile)" alt="">
				</div>
				<div :class="['bot-area',scrollMotion] ">
					<template v-if="invitationMainTextAndColorJson.nameCheckYn !== 'Y'">
						<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)">
							<p class="name">최신부</p>
							<p class="name">김신랑</p>
						</div>
						<div class="name-div" v-else>
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						</div>
					</template>
					<template v-else>
					<template v-if="(isEmpty(invitationMainTextAndColorJson.groomName) && isEmpty(invitationMainTextAndColorJson.brideName)) ">
						<div class="name-div" v-if="getGroomBrideNameIsEmpty(invitation)" :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">최신부</p>
							<p class="name">김신랑</p>
						</div>
						<div class="name-div" v-else :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitation.invitationInfoBrideLastName}}{{invitation.invitationInfoBrideFirstName}}</p>
							<p class="name">{{invitation.invitationInfoGroomLastName}}{{invitation.invitationInfoGroomFirstName}}</p>
						</div>
					</template>
						<div class="name-div"  :style="'color:'+invitationMainTextAndColorJson.nameColor">
							<p class="name">{{invitationMainTextAndColorJson.brideName}}</p>
							<p class="name">{{invitationMainTextAndColorJson.groomName}}</p>
						</div>
					</template>
					<template v-if="invitationMainTextAndColorJson.addressAndTimeCheckYn !== 'Y'">
						<p class="place" v-if="getTimeAndAddressIsEmpty(invitation)">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
						<p class="place">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
					</template>
					<template v-else>
						<template v-if="isEmpty(invitationMainTextAndColorJson.addressAndTime)">
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-if="getTimeAndAddressIsEmpty(invitation)">2025.01.08.(토) 1:30 PM<br />비비드마레호텔 5층 가든하우스홀</p>
							<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor">{{getKor2Datetime(invitation.invitationTimeDatetime)}}<br />{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
						</template>
						<p class="place" :style="'color:'+invitationMainTextAndColorJson.addressAndTimeColor" v-html="getAddressAndTimeToHtml(invitationMainTextAndColorJson.addressAndTime)"></p>
					</template>
				</div>
			</div>
		</template>
		
	</div>
</template>


<script>
export default {
  name: 'WritePreviewMain',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
	invitationMainTextAndColorJson(){
		return JSON.parse(this.invitation.invitationMainTextAndColorJson)
	},
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
  },
  methods: {

  },

}
</script>
