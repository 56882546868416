<template>	
  <div class="guest-book" v-if="invitation.invitationGuestBookYn === 'Y'">
		<div :class="['tit-wrap',scrollMotion]">
			<p class="sub-tit">GUEST BOOK</p>
			<p class="tit">{{invitation.invitationGuestBookTitle}}</p>
		</div>
		<div :class="['guest-book-slide',scrollMotion]">
			<div class="swiper guest-slide" v-if="!real">
				<div class="swiper-wrapper">
					<div class="swiper-slide">
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자1</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자2</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다. 미리보기를 위한 샘플 방명록입니다. 미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자3</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
					</div>
					<div class="swiper-slide">
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자4</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자5</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자6</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다. 미리보기를 위한 샘플 방명록입니다. 미리보기를 위한 샘플 방명록입니다.</div>
						</div>
						<div class="guest">
							<div class="top-area">
								<p class="name">작성자7</p>
								<div class="right">
									<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">2024.00.00</p>
									<button type="button"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
								</div>
							</div>
							<div class="txt">미리보기를 위한 샘플 방명록입니다.</div>
						</div>
					</div>
				</div>
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
			</div>
			<div class="swiper guest-slide" v-else-if="getInvitationGuestBookList.length>0">
				<div class="swiper-wrapper" > 
					<div class="swiper-slide" v-for="pages in Math.ceil( getInvitationGuestBookList.length/4) " :key="pages">
						<template  v-for="(invitationGuestBook,index) in getInvitationGuestBookList">
							<div class="guest" :key="index +'sub'" v-if="index < (pages)*4 && index >= (pages-1) * 4">
								<div class="top-area">
									<p class="name">{{invitationGuestBook.invitationGuestBookName}}</p>
									<div class="right">
										<p class="time" v-if="invitation.invitationGuestBookDateVisibleYn === 'Y'">{{getYYYYCommaMMCommaDDCommaTime(invitationGuestBook.registDt)}}</p>
										<button type="button"  @click="clickGuestBooDeletePopup(invitationGuestBook)"><img :src="`${publishpath}pub/images/icon-guest-book-delete.svg`" alt=""></button>
									</div>
								</div>
								<div class="txt" v-html="getLineReplace(invitationGuestBook.invitationGuestBookContent)"></div>
							</div>
						</template>
					</div>
				</div>
				<div class="swiper-button-next"></div>
				<div class="swiper-button-prev"></div>
			</div>
			<a href="javascript:" class="write-btn" @click="clickGuestBookWritePopup"><span>작성하기</span></a>
		</div>
	</div>
</template>


<script>
import EventBus from "@/utils/CommonEventBus";
import { mapGetters } from "vuex";
export default {
  name: 'WritePreviewGuestBook',
  props:['invitation','real','scrollMotion'],
  components: {},
  computed:{
      ...mapGetters(['getInvitationGuestBookList']),
	
  },
  data(){
    return {
    }
  },
  
  created(){
	if(this.real){
		const parameter = {
			invitationGuestBook: {
				invitationId: this.invitation.invitationId
			},
			context : this
		}
		this.$store.dispatch("REQUEST_LIST_INVITATION_GUEST_BOOK",parameter)
	}
  },
  mounted(){
  },
  methods: {
	clickGuestBookWritePopup(){

      const param = {
        flag: 'guestBookWritePop',
        invitation: this.invitation
      }
      EventBus.$emit('layerPopup',param)
	},
	clickGuestBooDeletePopup(invitationGuestBook){
		
      const param = {
        flag: 'guestBookDeletePop',
        invitationGuestBook: invitationGuestBook
      }
      EventBus.$emit('layerPopup',param)
	}

  },

}
</script>
