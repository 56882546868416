<template>	
	<div class="location">
		<div :class="['tit-wrap',scrollMotion]">
			<p class="sub-tit">LOCATION</p>
			<p class="tit">{{invitation.invitationAddressTitle}}</p>
		</div>
		<p :class="['place',scrollMotion]" v-if="getAddressIsEmpty(invitation)">비비드마레호텔 5층 가든하우스홀</p>
		<p :class="['place',scrollMotion]" v-else>{{invitation.invitationAddressBuildingName}} {{invitation.invitationAddressBuildingFloor}}</p>
		<p :class="['address',scrollMotion]" v-if="isEmpty(invitation.invitationAddress)">경남 창원시 성산구 원이대로 332</p>
		<p :class="['address',scrollMotion]" v-else>{{invitation.invitationAddress}}</p>
		<p :class="['call-num',scrollMotion]" v-if="isEmpty(invitation.invitationAddressBuildingNumber)"><a href="tel:070-8983-7401">070-8983-7401</a></p>
		<p :class="['call-num',scrollMotion]" v-else><a :href="'tel:' + invitation.invitationAddressBuildingNumber">{{invitation.invitationAddressBuildingNumber}}</a></p>
		
		
		<div :class="['map-area',scrollMotion]">
    		<KakaoMap2 :invitation="invitation" v-if="invitation.invitationAddressMapYn==='Y'" :real="real"/>
			
			<div class="map-choice-btn" v-if="invitation.invitationAddressNavigationBtnYn==='Y'">
				<a href="javascript:" @click="clickNaverMap" class="naver"><span>네이버지도</span></a>
				<a href="javascript:" @click="clickKakaoMap" class="kakao"><span>카카오네비</span></a>
				<a href="javascript:" @click="clickTmap" class="tmap"><span>티맵</span></a>
			</div>
		</div>
		<div class="transport" v-if="invitation.invitationTransportationYn === 'Y' && invitationTransportationList.length>0">
			<div :class="['list',scrollMotion]" v-for="(invitationTransportation, index) in invitationTransportationList" :key="index">
				<p class="tit">{{invitationTransportation.title}}</p>
				<p class="txt" v-html="getLineReplace(invitationTransportation.content)"></p>
			</div>
			
		</div>
	</div>
</template>


<script>
import KakaoMap2 from "@/components/common/KakaoMap2.vue";
export default {
  name: 'WritePreviewAddress',
  props:['invitation','real','scrollMotion'],
  components: {KakaoMap2},
  computed:{
	invitationTransportationList(){
		return JSON.parse(this.invitation.invitationTransportationJson);
	},
  },
  data(){
    return {
    }
  },
  
  created(){
    
  },
  mounted(){
        const script = document.createElement('script');
        script.src= "//developers.kakao.com/sdk/js/kakao.min.js";
        document.head.appendChild(script);
        script.onload = () => {
    		Kakao.init('a489a3f33e03e97a14fd62d585935b58');
		}
		

  },
  methods: {
	clickTmap(){

		var tmapTxt = 'https://apis.openapi.sk.com/tmap/app/routes?appKey=l7xx4a9bda6979b3420faa1eee6114d3ce3e';
		tmapTxt += '&name=' + this.invitation.invitationAddress
		tmapTxt += '&lon=' + this.invitation.invitationAddressX
		tmapTxt += '&lat=' + this.invitation.invitationAddressY
		location.href=tmapTxt
	},
	clickKakaoMap(){

		
			Kakao.Navi.start({
		      name: this.invitation.invitationAddress,
		      x: parseFloat(this.invitation.invitationAddressX),
		      y: parseFloat(this.invitation.invitationAddressY),
		      coordType: 'wgs84',
		    })


	},
	clickNaverMap(){
		location.href=`https://map.naver.com/v5/search/${this.invitation.invitationAddress}`
	}

  },

}
</script>
